<template>
  <!-- 承接中 货单跟踪 已取消 -->
  <div class="carrier-report-wrap">
    <div v-for="(item, index) in tableData" :key="index" class="g-search-form-table">
      <div class="mb20">
        <div class="flex-c">
          <UserInfoCard class="ml20" :user-info="item" :user-type="2" />
          <div class="mr20">
            <div class="value">
              <span class="mr10">上报 {{ item.submitCarCount }} 车</span>
              <span class="mr10">{{ $CONSTANT.priceTypeMap[+item.submitPriceType===1] }}</span><span>{{ $parseNumber(item.submitPrice)||'-' }} {{ $CONSTANT.transportPriceUnitMap.O[+confirmInfo.priceUnit] }}</span>
              <span :class="['ml10',$CONSTANT.taxColorMap[+item.needTax]]">{{ $CONSTANT.taxMap[+item.needTax] }}</span>
            </div>
          </div>
          <div>报车渠道：平台定向</div>
        </div>
      </div>
      <Table
        :table-cols="tableCols"
        :table-data="item.carOrderListVOList"
        :show-pagination="false"
      >
        <template v-slot="{ col, row }">
          <div v-if="col.prop === 'truckNo'" class="ellipsis">
            <span class="mr5">{{ row.truckNo||'-' }}</span>
            <span :class="$CONSTANT.carTypeColorMap[row.isWx]">{{ $CONSTANT.carTypeMap[row.isWx]||'-' }}</span>
          </div>
          <div v-if="col.prop === 'driverName'" class="ellipsis">
            <span class="mr5">{{ row.driverName||'-' }}</span>
            <span>{{ row.driverPhone||'-' }}</span>
          </div>
          <div v-if="col.prop === 'escortName'" class="ellipsis">
            <span class="mr5">{{ row.escortName||'-' }}</span>
            <span>{{ row.escortPhone||'-' }}</span>
          </div>
        </template>
      </Table>
    </div>
  </div>
</template>

<script>
import supplyTask from '@/api/goodsOrder/supplyTask';
export default {
  props: {
    orderId: {
      type: [Number, String],
      default() {
        return 1;
      },
    },
    // orderInformation: {
    //   type: Object,
    //   default() {
    //     return {};
    //   },
    // },
  },

  data() {
    return {
      tableCols: [
        { label: '车单编号', prop: 'carSerial', width: 160 },
        { label: '车牌号', prop: 'truckNo', type: 'customize' },
        { label: '挂车号', prop: 'guaNo' },
        { label: '驾驶员', prop: 'driverName', type: 'customize', width: 160 },
        { label: '押运员', prop: 'escortName', type: 'customize', width: 160 },
        { label: '取消时间', prop: 'createTime', type: 'filterTime', width: 165 },
        { label: '小五姓名', type: 'objShow', objShow: { keyPar: 'xwUserVO', keyChild: 'name' }},
      ],
      tableData: [],
      confirmInfo: {},
    };
  },
  created() {
    this.getList();
  },
  methods: {
    getList() {
      const params = {
        id: this.orderId,
      };
      supplyTask.orderCarCancelQxList(params).then(res => {
        this.confirmInfo = res || {};
        this.tableData = res?.carOrderReportGroupVOList	 || [];
        this.tableDataFixed = res?.carOrderReportGroupVOList	 || [];
      });
    },
  },
};

</script>
<style lang='scss' scoped>
.carrier-report-wrap{
  .g-table-c{
    padding: 0;
  }
  .bold-text{
    font-weight: bold;
    // font-size: 16px;
    color: $Black;
  }
  .g-search-form-table{
    margin-bottom: 30px;
    width: 100%;
  }
}
</style>
