<template>
  <el-dialog
    title="修改承接价格"
    :visible="dialogVisible"
    width="600px"
    top="20vh"
    :destroy-on-close="true"
    @open="open"
    @close="close"
  >
    <div><el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="150px">
      <el-form-item label="承接价格（元）" prop="acceptPrice">
        <el-input v-model="form.acceptPrice" style="width: 70%;" placeholder="请输入" clearable @input="validateNumber('acceptPrice',9,2)">
          <template v-slot:append>
            <span class="unit">{{ $CONSTANT.transportPriceUnitMap.O[form.unit] }}</span>
          </template>
        </el-input>
      </el-form-item>
      <el-form-item prop="needTax">
        <el-radio-group v-model="form.needTax" :disabled="true">
          <el-radio :label="1">含税</el-radio>
          <el-radio :label="0">不含税</el-radio>
        </el-radio-group>
      </el-form-item>
    </el-form></div>
    <span slot="footer">
      <el-button @click="close">取消</el-button>
      <el-button type="primary" @click="submit">确定</el-button>
    </span>
    <!-- <span slot="footer">
      <el-button type="primary" @click="submit">推送</el-button>
    </span> -->
  </el-dialog>
</template>

<script>
import supplyTask from '@/api/goodsOrder/supplyTask';
export default {
  props: {
    dialogVisible: {
      type: Boolean,
      default() {
        return false;
      },
    },
    originInfo: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  data() {
    return {
      form: {
        acceptPrice: '',
        unit: 1,
        needTax: 1,
      },
      rules: {
        acceptPrice: [
          { required: true, message: '请输入承接价格', trigger: 'change' },
        ],
      },
    };
  },
  methods: {
    open() {
      this.form = JSON.parse(JSON.stringify(this.originInfo));
    },
    close() {
      this.$emit('update:dialogVisible', false);
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            id: this.form.id,
            price: this.$parseNumber(this.form.acceptPrice, 100, 'times'),
            // total: this.form.acceptCarNumber,
          };
          supplyTask.orderGoodsReportUpdate(params).then(res => {
            if (res) {
              this.$baseMessage('修改成功!', 'success');
              this.$emit('dialogSubmit', this.form);
              this.close();
            } else {
              this.$baseMessage('修改失败!', 'error');
            }
          });
        }
      });
    },
    validateNumber(val, limitNum, decimals) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.form[val])) {
        this.form[val] = '';
      }
      // 保留两位小数
      const price = this.form[val].toString();
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, limitNum);
      if (pArr.length > 1 && decimals) {
        this.form[val] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.form[val] = pArr[0];
      }
    },
  },
};

</script>
<style lang='scss' scoped>
.table-list{
  display: flex;
  border: 1px solid $grey;
  border-radius: 8px;
  .left,.right{
    width: 50%;
    padding: 15px 15px 0 15px;
    .g-table-c{
      padding: 0;
    }
  }
  .left{
    border-right: 1px solid $grey;
    ::v-deep{
      .el-pagination{
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;
      }
    }
  }
  .right{
    padding-top: 25px;
    .select-table-wrap{
      margin-top:32px;
    }
  }
}

</style>
