<template>
  <!-- 承接推送信息列表  承接推送小五列表 -->
  <div class="accept-push-info-wrap">
    <div class="card-title">承接信息</div>
    <div class="flex">
      <div class="info-item">
        <div class="label">承接价格：</div>
        <div class="value"><span>{{ originInfo.acceptPrice }} {{ $CONSTANT.transportPriceUnitMap.O[originInfo.unit] }}</span><span v-if="showActions&&[0,1].includes(+originInfo.state)&&priceChange===1&&!+originInfo.priceType===2" class="main-c-cursor ml20" @click="acceptDialog = true;">修改</span></div>
      </div>
      <div class="info-item">
        <div class="label">承接车数：</div>
        <div class="value">
          <!-- <span class="original-truckNum">15</span> -->
          <span>{{ originInfo.acceptCarNumber }} 车</span>
        </div>
      </div>
    </div>
    <div v-if="[2,3].includes(+orderInformation.source)" class="flex">
      <div class="info-item">
        <div class="label">信息费：</div>
        <div class="value">
          {{ originInfo.infoPrice||'-' }} {{ $CONSTANT.transportPriceUnitMap.O[2] }}
        </div>
      </div>
    </div>
    <div class="card-title pt10">推送设置</div>
    <div class="flex">
      <div class="info-item">
        <div class="label">优先级：</div>
        <div class="value">{{ $CONSTANT.priorityMap[+pushSetBaseInfo.priority] }}</div>
      </div>
      <div class="info-item">
        <div class="label">是否推送至平台优选：</div>
        <div class="value">{{ +pushSetBaseInfo.isPushPlatForm===1?'是':'否' }}</div>
      </div>
    </div>
    <div class="flex">
      <div class="info-item2">
        <div class="label">找车指导价：</div>
        <!-- 运价 -->
        <div v-if="+pushSetBaseInfo.priceType===1" class="value">
          <span class="mr10">运价</span><span>{{ pushSetBaseInfo.price||'-' }} {{ $CONSTANT.transportPriceUnitMap.O[+pushSetBaseInfo.priceUnit] }}</span>
          <span :class="['ml10',$CONSTANT.taxColorMap[+pushSetBaseInfo.needTax]]">{{ $CONSTANT.taxMap[+pushSetBaseInfo.needTax]||'-' }}</span>
          <span class="grey-c fz_12 ml10">预估利润率 {{ profit }}%</span>
        </div>
        <div v-else class="value">
          <span class="mr10">信息费</span><span>{{ pushSetBaseInfo.price||'-' }} 元/车</span>
          <span :class="['ml10',$CONSTANT.taxColorMap[+pushSetBaseInfo.needTax]]">{{ $CONSTANT.taxMap[+pushSetBaseInfo.needTax]||'-' }}</span>
          <span class="grey-c fz_12 ml10">预估利润率 {{ InfoProfit }}%</span>
        </div>
      </div>
    </div>
    <div class="flex">
      <div class="info-item">
        <div class="label">推送人员：</div>
        <div v-if="showActions"><span class="main-c cursor add" @click="add">新增推送小五</span></div>
      </div>
    </div>
    <div class="push-list">
      <div class="push-table">
        <el-col :span="18">
          <el-table
            :data="recordList"
            style="width: 100%"
            border
            max-height="505px"
          >
            <el-table-column
              prop="createTime"
              label="推送时间"
              align="center"
              min-width="150"
            >
              <template slot-scope="{ row }">
                <div>
                  {{ row.createTime|parseTime }}
                </div>
              </template>
            </el-table-column>
            <el-table-column
              prop="name"
              label="小五姓名"
              align="center"
              min-width="150"
            />
            <el-table-column
              prop="phone"
              label="联系电话"
              align="center"
              min-width="150"
            />
            <el-table-column
              prop="createTime"
              label="是否推送车队"
              align="center"
              min-width="120"
            >
              <template slot-scope="{ row }">
                <div class="push-state">
                  <span>
                    {{ pushTransMap[row.state] }}
                  </span>
                  <span v-if="row.state===0" class="send-remind main-c-cursor" @click="sendRemind(row)">发送提醒</span>
                </div>
              </template>
            </el-table-column>
          </el-table>
        </el-col>
      </div>
    </div>
    <AcceptPriceDialog :dialog-visible.sync="acceptDialog" :origin-info="originInfo" @dialogSubmit="getOrderGoodsReportDetail()" />
    <DialogTable :dialog-visible.sync="showDialog" :select-list="selectUser" :disable-i-d-list="disableIDList" @dialogSubmit="dialogSubmit" />
  </div>
</template>

<script>
import supplyTask from '@/api/goodsOrder/supplyTask';
import { parseTime } from '@/utils';
import DialogTable from '@/views/goodsOrder/components/detail/dialogTable';
import AcceptPriceDialog from '@/views/goodsOrder/components/detail/acceptPriceDialog';
export default {
  components: { DialogTable, AcceptPriceDialog },
  filters: { parseTime },
  props: {
    // 是否展示操作按钮
    showActions: {
      type: Boolean,
      default() {
        return true;
      },
    },
    orderId: {
      type: [Number, String],
      default() {
        return null;
      },
    },
    orderInformation: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      acceptDialog: false,
      originInfo: {}, // 承接信息数据
      pushSetBaseInfo: {}, // 推送设置基础数据
      recordList: [],
      // userId: '',
      selectUser: [],
      addselectUser: [],
      showDialog: false,
      disableIDList: [],
      pushTransMap: {
        1: '是',
        0: '否',
      },
    };
  },

  computed: {
    // 是否可调价
    priceChange() {
      if (this.orderInformation.extMap) {
        return +this.orderInformation.extMap.priceChange || 0;
      } else {
        return 0;
      }
    },
    profit() {
      if (this.originInfo.acceptPrice && this.pushSetBaseInfo.price && this.pushSetBaseInfo.priceType === 1) {
        const carryZong = this.pushSetBaseInfo.price;
        const orderZong = this.originInfo.acceptPrice;
        const priceDiff = orderZong - carryZong;
        const percent = Math.round((priceDiff / orderZong) * 100 * 100) / 100;
        return percent;
      } else {
        return '-';
      }
    },
    // 货源承接-预估利润率（%） ①车队指导价-含税/不含税：（货主运价-车队指导价）/货主运价 ②信息费：信息费/（货主运价*30）
    InfoProfit() {
      if (this.originInfo.acceptPrice && this.pushSetBaseInfo.price && this.pushSetBaseInfo.priceType === 2) {
        const carryZong = this.pushSetBaseInfo.price;
        const orderZong = this.originInfo.acceptPrice * 33;
        const percent = Math.round((carryZong / orderZong) * 100 * 100) / 100;
        return percent;
      } else {
        return '-';
      }
    },
  },

  created() {
    this.getOrderGoodsReportDetail();
    this.getBasePushSet();
    this.getList();
  },
  methods: {
    // 获取报价详情（获取承接价格和承接车数）
    getOrderGoodsReportDetail() {
      const params = {
        gId: this.orderId,
        type: 1, // type 1前线 2小五 3车队
      };
      supplyTask.orderGoodsReportDetail(params).then(res => {
        if (res) {
          this.originInfo = {
            ...res,
            id: res.id,
            acceptPrice: res.priceType === 2 ? this.$parseNumber(res.showPrice) || '' : this.$parseNumber(res.price) || '', // 承接价格
            infoPrice: res.priceType === 2 ? this.$parseNumber(res.price) || '' : '', // 信息费
            acceptCarNumber: res.truckNum || 0,
            unit: res.priceUnit,
            needTax: +res.needTax || 0,
          };
        }
      });
    },
    // 推送设置详情
    getBasePushSet() {
      supplyTask.orderGoodsPushDetail(this.orderId).then(res => {
        this.pushSetBaseInfo = {
          priority: res.priority || 3,
          isPushPlatForm: res.isPushPlatForm || 0,
          priceType: res.priceType || 1,
          price: this.$parseNumber(res.price) || 0,
          priceUnit: res.priceUnit || 1,
          needTax: res.needTax || 1,
        };
      });
    },
    // 获取推送小五记录列表
    getList() {
      supplyTask.orderGoodsRecordXwList({ id: this.orderId }).then(res => {
        this.recordList = res?.goodsRecordVOList || [];
      });
    },
    add() {
      const disableIDList = this.recordList.map(item => item.userId) || [];
      this.disableIDList = disableIDList || [];
      this.showDialog = true;
    },
    submit() {
      const params = {
        gId: this.orderId, // 货单Id
        type: 2, // 2推送小五 3推送车队
        priority: this.pushSetBaseInfo.priority, // 优先级
        price: this.$parseNumber(this.pushSetBaseInfo.price, 100, 'times'), // 费用
        isPushPlatForm: this.pushSetBaseInfo.isPushPlatForm, // 是否推送大厅
        priceType: Number(this.pushSetBaseInfo.priceType), // 费用类型 1运价  2信息费
        needTax: this.pushSetBaseInfo.needTax, // 是否含税 1含税 0不含税
        enable: 1, // 0货单未承接 1货单已承接
        userIdList: this.addselectUser.map(d => d.id) || [], // 小五UserId列表
      };
      supplyTask.orderGoodsPushXw(params).then(res => {
        if (res) {
          this.$baseMessage('提交成功!', 'success');
          this.getList();
          this.$emit('actionSuccess');
        }
      });
    },
    dialogSubmit(data) {
      this.addselectUser = data;
      this.submit();
    },
    // 发送提醒小五推送车队
    sendRemind(data) {
      const params = {
        id: this.orderId, // 货单Id
        ids: [data.userId],
      };
      supplyTask.orderGoodsRecordPushXw(params).then(res => {
        if (res) {
          this.$baseMessage('提醒发送成功!', 'success');
        }
      });
    },
  },
};

</script>
<style lang='scss' scoped>
.accept-push-info-wrap{
  display: flex;
  flex-direction: column;
  color: #04122B;
  .label{
    text-align: right;
    width:140px;
    color: #A4A4A4;
  }
  .value{
    flex: 1;
  }
  .info-item{
    width:350px;
    display: flex;
    line-height: 40px;
    .original-truckNum{
      text-decoration: line-through;
    }
  }
  .info-item2{
    width:500px;
    display: flex;
    line-height: 40px;
  }
  .push-list{
    margin-bottom: 10px;
    margin-left: 140px;
  }
  .push-state{
    padding-left: 31%;
    display: flex;
    justify-content: flex-start;
    .send-remind{
      margin-left: 5px;
      font-size: 12px;
    }
  }
}
</style>
