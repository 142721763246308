<template>
  <!-- 承接推送信息  （货源承接 未承接详情） 竞价-->
  <div class="accept-push-info-wrap">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="150px" :disabled="showDetail">
      <el-row>
        <el-col :span="12">
          <el-form-item label="承接价格（元）" prop="price">
            <el-input v-model="form.price" placeholder="请输入承接价格" clearable @input="validateNumber('price',9,2)">
              <template v-slot:append>
                <span class="unit">{{ $CONSTANT.transportPriceUnitMap.O[+orderInformation.priceUnit] }}</span>
              </template>
            </el-input>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="12">
          <el-form-item label="承接车数（车）" prop="truckNum">
            <el-input v-model="form.truckNum" placeholder="请输入承接车数" clearable @input="validateNumber('truckNum',9,0)" />
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>
    <template v-if="!showDetail&&hasExpire">
      <el-button type="primary" class="submit-btn" @click="submit">提交</el-button>
      <el-button @click="cancel">取消</el-button>
    </template>

  </div>
</template>

<script>
import supplyTask from '@/api/goodsOrder/supplyTask';

export default {
  props: {
    orderId: {
      type: [Number, String],
      default() {
        return null;
      },
    },
    // 是否展示详情（承接记录详情下 展示）
    showDetail: {
      type: Boolean,
      default() {
        return false;
      },
    },
    orderInformation: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      nowTime: new Date().getTime(),
      form: {
        price: '',
        truckNum: '',
      },
      rules: {
        price: [
          { required: true, message: '请输入承接价格', trigger: 'change' },
        ],
        truckNum: [
          { required: true, message: '请输入承接车数', trigger: 'change' },
          { validator: this.validateTruckNum },
        ],
      },
    };
  },
  computed: {
    hasExpire() {
      if (this.orderInformation.extMap && this.orderInformation.extMap.bidExpireTime) {
        return this.orderInformation.extMap.bidExpireTime > this.nowTime;
      } else {
        return false;
      }
    },
  },
  created() {
    if (this.showDetail) {
      this.getOrderGoodsReportDetail();
    }
  },
  methods: {
    // 获取报价详情（获取承接价格和承接车数）
    getOrderGoodsReportDetail() {
      const params = {
        gId: this.orderId,
        type: 1, // type 1前线 2小五 3车队
      };
      supplyTask.orderGoodsReportDetail(params).then(res => {
        if (res) {
          this.form = {
            price: this.$parseNumber(res.price) || '',
            truckNum: res.truckNum || 0,
          };
        }
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          const params = {
            price: this.$parseNumber(this.form.price, 100, 'times'),
            truckNum: +this.form.truckNum,
            gId: this.orderId,
            titleId: 1,
            titleName: 'AUV平台公司',
            titleState: 1,
          };
          supplyTask.orderMiddlePriceReport(params).then(res => {
            if (res) {
              this.$baseMessage('提交成功!', 'success');
              this.$refs.form.resetFields();
              this.$router.replace({
                path: '/goodsOrder/supplyTask/detail',
                query: {
                  id: this.orderId,
                  status: 2,
                  isSend: 0,
                },
              });
              this.$emit('toSendDetail');
            }
          });
        }
      });
    },
    cancel() {
      this.form = {};
      this.$refs.form.resetFields();
    },
    // 验证承接车数
    validateTruckNum(rule, value, callback) {
      if (parseFloat(value) > this.orderInformation.truckNum) {
        callback(new Error('承接车数不允许超出发布的承运车数'));
      } else {
        callback();
      }
    },
    validateNumber(val, limitNum, decimals) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.form[val])) {
        this.form[val] = '';
      }
      // 保留两位小数
      const price = this.form[val].toString();
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, limitNum);
      if (pArr.length > 1 && decimals) {
        this.form[val] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.form[val] = pArr[0];
      }
    },
  },
};

</script>
<style lang='scss' scoped>
.accept-push-info-wrap{
  .profit-tip{
    height: 36px;
    font-size: 12px;
    color: $grey;
    margin-left: 10px;
  }
  .submit-btn{
    margin-top: 50px;
    margin-left: 150px;
  }
}
</style>
