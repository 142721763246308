<template>
  <!-- 承接推送信息  （货源承接 未承接详情） 定价1/竞价2-->
  <div class="accept-push-info-wrap">
    <el-form ref="form" :model="form" :rules="rules" label-position="right" label-width="150px">
      <div class="card-title">承接信息</div>
      <div class="flex">
        <!-- 修改按钮出现条件 state等于1||0并且货单详情拓展字段priceChange等于1 外协货单不以信息费外协时!+originInfo.priceType===2-->
        <div class="has-info-price-wrap">
          <el-form-item label="承接价格">
            <div>
              <span><span class="red-c">{{ originInfo.acceptPrice }}</span> {{ $CONSTANT.transportPriceUnitMap.O[unit] }}</span><span v-if="showActions&&[0,1].includes(+originInfo.state)&&priceChange===1&&+originInfo.priceType!==2" class="main-c-cursor ml20" @click="acceptDialog = true;">修改</span>
            </div>
          </el-form-item>
          <!-- v-if="+originInfo.priceType===2" -->
          <el-form-item v-if="[2,3].includes(+orderInformation.source)" label="信息费">
            <div>
              <span>
                <span class="red-c">{{ originInfo.infoPrice||'-' }}</span> {{ $CONSTANT.transportPriceUnitMap.O[2] }}
              </span>
            </div>
          </el-form-item>
        </div>
        <el-form-item label="承接车数">
          <div>
            <span>{{ originInfo.acceptCarNumber }} 车</span>
          </div>
        </el-form-item>
      </div>
      <template v-if="+orderInformation.type===1">
        <div class="card-title">推送信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="优先级" prop="priority">
              <el-select v-model="form.priority" clearable placeholder="请选择">
                <el-option v-for="item in $CONSTANT.priorityArrayMap" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否推送至平台优选" prop="isPushPlatForm">
              <el-select v-model="form.isPushPlatForm" clearable placeholder="请选择">
                <el-option label="是" :value="1" />
                <el-option label="否" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <!-- 外协货单 承运商货单 priceType===2如果以信息费外协则 承接价格不可修改 且下方车队指导价方式信息费输入框自动切换且置灰 -->
        <el-form-item label="车队指导价" prop="guidingFreight">
          <el-row class="flex-c">
            <el-col :span="13" class="flex-c">
              <el-radio v-model="priceType" style="width:110px" label="1" :disabled="originInfo.priceType===2">运价（{{ $CONSTANT.transportPriceUnitMap.O[unit] }}）   </el-radio>
              <el-form-item label="" prop="needTax" class="tax-wrap">
                <el-select v-model="form.needTax" clearable placeholder="请选择" :disabled="true" style="margin-bottom:0">
                  <el-option v-for="item in $CONSTANT.taxArrayMap" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-input v-model="form.guidingFreight" v-focus class="red-input" :disabled="+priceType===2" placeholder="请输入" clearable @input="validateNumber('guidingFreight',9,2)" />
            </el-col>
            <el-col :span="11">
              <div class="profit-tip">预估利润率：{{ profit }}%</div>
            <!-- 合计预估利润/货主运价x30吨x承接车数 -->
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="" prop="guidingInformationMoney">
          <el-row>
            <el-col :span="13" class="flex-c">
              <el-radio v-model="priceType" style="width:110px" label="2">信息费（元/车）</el-radio>
              <el-input v-model="form.guidingInformationMoney" class="red-input" :disabled="+priceType===1" placeholder="请输入" clearable @input="validateNumber('guidingInformationMoney',9,2)" />
            </el-col>
            <el-col :span="11">
              <div class="profit-tip">预估利润率：{{ InfoProfit }}%</div>
            <!-- 合计预估利润/货主运价x30吨x承接车数 -->
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="推送人员" prop="user">
          <el-col :span="18">
            <span class="main-c cursor" @click="add">新增推送小五</span>
            <el-table
              v-if="form.user.length"
              class="select-table-wrap"
              :data="form.user"
              style="width: 100%"
              border
              max-height="505px"
            >
              <el-table-column
                prop="userName"
                label="姓名"
                align="center"
              />
              <el-table-column
                prop="phone"
                label="联系电话"
                align="center"
              />
              <el-table-column
                label="操作"
                align="center"
              >
                <template slot-scope="{ row }">
                  <div class="main-c cursor" @click="deleteSelectItem(row)">移除</div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-form-item>
      </template>
      <template v-if="bidSubmit&&+orderInformation.type===2">
        <div class="card-title">推送信息</div>
        <el-row>
          <el-col :span="12">
            <el-form-item label="优先级" prop="priority">
              <el-select v-model="form.priority" clearable placeholder="请选择">
                <el-option v-for="item in $CONSTANT.priorityArrayMap" :key="item.value" :label="item.label" :value="item.value" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item label="是否推送至平台优选" prop="isPushPlatForm">
              <el-select v-model="form.isPushPlatForm" clearable placeholder="请选择">
                <el-option label="是" :value="1" />
                <el-option label="否" :value="0" />
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="车队指导价" prop="guidingFreight">
          <el-row class="flex-c">
            <el-col :span="13" class="flex-c">
              <el-radio v-model="priceType" style="width:110px" label="1">运价（{{ $CONSTANT.transportPriceUnitMap.O[unit] }}）   </el-radio>
              <el-form-item label="" prop="needTax" class="tax-wrap">
                <el-select v-model="form.needTax" clearable placeholder="请选择" :disabled="true" style="margin-bottom:0">
                  <!-- <el-option label="含税" :value="1" />
                <el-option label="不含税" :value="0" /> -->
                  <el-option v-for="item in $CONSTANT.taxArrayMap" :key="item.value" :label="item.label" :value="item.value" />
                </el-select>
              </el-form-item>
              <el-input v-model="form.guidingFreight" v-focus class="red-input" :disabled="+priceType===2" placeholder="请输入" clearable @input="validateNumber('guidingFreight',9,2)" />
            </el-col>
            <el-col :span="11">
              <div class="profit-tip">预估利润率：{{ profit }}%</div>
            <!-- 合计预估利润/货主运价x30吨x承接车数 -->
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="" prop="guidingInformationMoney">
          <el-row>
            <el-col :span="13" class="flex-c">
              <el-radio v-model="priceType" style="width:110px" label="2">信息费（元/车）</el-radio>
              <el-input v-model="form.guidingInformationMoney" class="red-input" :disabled="+priceType===1" placeholder="请输入" clearable @input="validateNumber('guidingInformationMoney',9,2)" />
            </el-col>
            <el-col :span="11">
              <div class="profit-tip">预估利润率：{{ InfoProfit }}%</div>
            <!-- 合计预估利润/货主运价x30吨x承接车数 -->
            </el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="推送人员" prop="user">
          <el-col :span="18">
            <span class="main-c cursor" @click="add">新增推送小五</span>
            <el-table
              v-if="form.user.length"
              class="select-table-wrap"
              :data="form.user"
              style="width: 100%"
              border
              max-height="505px"
            >
              <el-table-column
                prop="userName"
                label="姓名"
                align="center"
              />
              <el-table-column
                prop="phone"
                label="联系电话"
                align="center"
              />
              <el-table-column
                label="操作"
                align="center"
              >
                <template slot-scope="{ row }">
                  <div class="main-c cursor" @click="deleteSelectItem(row)">移除</div>
                </template>
              </el-table-column>
            </el-table>
          </el-col>
        </el-form-item>
      </template>
    </el-form>
    <template v-if="+orderInformation.type===1||(bidSubmit&&+orderInformation.type===2)">
      <el-button v-if="showActions" type="primary" class="submit-btn" @click="submit">提交</el-button>
    </template>
    <!-- <template v-if="bidSubmit&&+orderInformation.type===2">
      <el-button v-if="showActions" type="primary" class="submit-btn" @click="submit">提交</el-button>
    </template> -->
    <AcceptPriceDialog :dialog-visible.sync="acceptDialog" :origin-info="originInfo" @dialogSubmit="getOrderGoodsReportDetail()" />
    <DialogTable :dialog-visible.sync="showDialog" :select-list="form.user" @dialogSubmit="dialogSubmit" />
  </div>
</template>

<script>
import supplyTask from '@/api/goodsOrder/supplyTask';
import AcceptPriceDialog from '@/views/goodsOrder/components/detail/acceptPriceDialog';
import DialogTable from '@/views/goodsOrder/components/detail/dialogTable';
export default {
  components: { AcceptPriceDialog, DialogTable },
  directives: {
    focus: {
      inserted(el) {
        el.querySelector('input').focus();
      },
    },
  },
  props: {
    // 是否展示操作按钮
    showActions: {
      type: Boolean,
      default() {
        return true;
      },
    },
    orderId: {
      type: [Number, String],
      default() {
        return null;
      },
    },
    orderInformation: {
      type: Object,
      default() {
        return {};
      },
    },
  },

  data() {
    return {
      acceptDialog: false,
      originInfo: {
        acceptPrice: '',
        acceptCarNumber: '',
        unit: 1,
        needTax: '',
      },
      unit: 1,
      priceType: '1',
      priceChange: 0, // 是否可调价
      form: {
        // acceptPrice: '',
        // acceptCarNumber: '',
        priority: 3,
        isPushPlatForm: 0,
        needTax: 0, // 后期接口自动赋值
        guidingFreight: '',
        user: [],
      },
      rules: {
        // acceptPrice: [
        //   { required: true, message: '请输入承接价格', trigger: 'change' },
        // ],
        // acceptCarNumber: [
        //   { required: true, message: '请输入承接车数', trigger: 'change' },
        // ],
        priority: [
          { required: true, message: '请选择优先级', trigger: 'change' },
        ],
        isPushPlatForm: [
          { required: true, message: '请选择是否推送至平台优选', trigger: 'change' },
        ],
        guidingFreight: [
          { required: true, message: '请输入车队指导价', trigger: 'change' },
        ],
        guidingInformationMoney: [
          { required: false, message: '请输入信息费', trigger: 'change' },
        ],
        user: [
          { required: true, message: '请选择推送人员', trigger: 'blur' },
        ],
      },
      showDialog: false,
    };
  },

  computed: {
    // 竞价模式下 true显示可填写推送信息模块 false不展示
    bidSubmit() {
      if (this.orderInformation && this.orderInformation.extMap && this.orderInformation.extMap.bidSubmit) {
        return true;
      } else {
        return false;
      }
    },
    profit() {
      if (this.orderInformation && this.form.guidingFreight) {
        const carryZong = this.form.guidingFreight;
        const orderZong = this.originInfo.acceptPrice;
        const priceDiff = orderZong - carryZong;
        const percent = Math.round((priceDiff / orderZong) * 100 * 100) / 100;
        return percent;
      } else {
        return '-';
      }
    },
    // 货源承接-预估利润率（%） ①车队指导价-含税/不含税：（货主运价-车队指导价）/货主运价 ②信息费：信息费/（货主运价*30）
    InfoProfit() {
      if (this.orderInformation && this.form.guidingInformationMoney) {
        const carryZong = this.form.guidingInformationMoney;
        const orderZong = this.originInfo.acceptPrice * 33;
        const percent = Math.round((carryZong / orderZong) * 100 * 100) / 100;
        return percent;
      } else {
        return '-';
      }
    },
  },

  watch: {
    orderInformation: {
      handler: function(val) {
        if (val) {
          this.form.needTax = this.orderInformation.extMap ? +this.orderInformation.extMap.needTax || 0 : 0;
          this.unit = this.orderInformation.priceUnit || 1;
          this.priceChange = this.orderInformation.extMap ? +this.orderInformation.extMap.priceChange || 0 : 0;
        }
      },
      immediate: true,
    },
    priceType: {
      // immediate: true,
      handler(val) {
        if (+val === 1) {
          // 选择运价情况
          this.rules.guidingInformationMoney[0].required = false;
          this.rules.guidingFreight[0].required = true;
          this.form.guidingInformationMoney = '';
          this.$refs.form.validateField(['guidingInformationMoney']);
        } else {
          // 选择信息费情况
          this.rules.guidingInformationMoney[0].required = true;
          this.rules.guidingFreight[0].required = false;
          this.form.guidingFreight = '';
          this.$refs.form.validateField(['guidingFreight']);
        }
      },
    },
  },

  created() {
    this.getOrderGoodsReportDetail();
    this.getList();
  },
  methods: {
    // 获取报价详情（获取承接价格和承接车数）
    getOrderGoodsReportDetail() {
      const params = {
        gId: this.orderId,
        type: 1, // type 1前线 2小五 3车队
      };
      supplyTask.orderGoodsReportDetail(params).then(res => {
        if (res) {
          // priceType===2说明以信息费外协 承接价格取showPrice 信息费取price
          // priceType===1说明无信息费 承接价格取price 信息费无值
          this.originInfo = {
            ...res,
            id: res.id,
            acceptPrice: res.priceType === 2 ? this.$parseNumber(res.showPrice) || '' : this.$parseNumber(res.price) || '', // 承接价格
            infoPrice: res.priceType === 2 ? this.$parseNumber(res.price) || '' : '', // 信息费
            acceptCarNumber: res.truckNum || 0,
            unit: this.unit,
            needTax: +res.needTax || this.form.needTax,
          };
          this.form.guidingFreight = this.originInfo.acceptPrice || '';
          // 如果是外协货单货主承运商货单则车队指导价方式都置灰不可点击
          if (+this.originInfo.priceType === 2) {
            this.priceType = '2';
            this.form.guidingInformationMoney = this.originInfo.infoPrice || '';
          }
        }
      });
    },
    getList() {
      supplyTask.orderGoodsRecordXwList({ id: this.orderId }).then(res => {
        // 有数据说明推送过 直接跳转到承接中
        if (res) {
          // 跳转到承接中详情
          this.$router.replace({
            path: '/goodsOrder/supplyTask/detail',
            query: {
              id: this.orderId,
              status: 2,
              isSend: 1,
            },
          });
          this.$emit('toSendDetail');
        }
      });
    },
    add() {
      this.showDialog = true;
    },
    deleteSelectItem(data) {
      this.form.user.forEach((item, i) => {
        if (data.id === item.id) {
          this.form.user.splice(i, 1);
        }
      });
    },
    submit() {
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.form.userDTOList = this.form.user;
          const params = {
            gId: this.orderId, // 货单Id
            type: 2, // 2推送小五 3推送车队
            priority: this.form.priority, // 优先级
            price: +this.priceType === 1 ? this.$parseNumber(this.form.guidingFreight, 100, 'times') : this.$parseNumber(this.form.guidingInformationMoney, 100, 'times'), // 费用
            isPushPlatForm: this.form.isPushPlatForm, // 是否推送大厅
            priceType: Number(this.priceType), // 费用类型 1运价  2信息费
            needTax: this.form.needTax, // 是否含税 1含税 0不含税
            enable: 0, // 0货单未承接 1货单已承接
            userIdList: this.form.user.map(d => d.id) || [], // 小五UserId列表
          };
          supplyTask.orderGoodsPushXw(params).then(res => {
            if (res) {
              this.$baseMessage('提交成功!', 'success');
              this.$refs.form.resetFields();
              // 跳转到承接中详情
              this.$router.replace({
                path: '/goodsOrder/supplyTask/detail',
                query: {
                  id: this.orderId,
                  status: 2,
                  isSend: 1,
                },
              });
              this.$emit('toSendDetail');
            }
          });
        }
      });
    },
    dialogSubmit(data) {
      this.form.user = data;
      this.$refs.form.validateField(['user']);
    },
    validateNumber(val, limitNum, decimals) {
      // 正则限制输入的金额不能为中文、特殊字符、两位小数
      const reg = /^[0-9]+.?[0-9]*/;
      // 如果不符合正则，提示
      if (!reg.test(+this.form[val])) {
        this.form[val] = '';
      }
      // 保留两位小数
      const price = this.form[val].toString();
      const pArr = price.split('.');
      pArr[0] = pArr[0].substr(0, limitNum);
      if (pArr.length > 1 && decimals) {
        this.form[val] = pArr[0] + '.' + pArr[1].substr(0, decimals);
      } else {
        this.form[val] = pArr[0];
      }
      this.$forceUpdate();
    },
  },
};

</script>
<style lang='scss' scoped>
.accept-push-info-wrap{
  .has-info-price-wrap{
    .el-form-item{
      margin-bottom: 0;
    }
  }
  .original-truckNum{
      text-decoration: line-through;
    }
  .profit-tip{
    font-size: 12px;
    color: $grey;
    margin-left: 10px;
  }
  .submit-btn{
    margin-left: 150px;
  }
  .tax-wrap{
    margin-bottom: 0!important;
  }
  .red-input{
    ::v-deep{
      >input{
        color: $red;
      }
    }
  }
}
</style>
